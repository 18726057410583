import React, { useState } from 'react';
import './App.css';
import logo from './images/Refractio-logo.svg';
import mainImage from './images/look-to-camera.png';
import icone1 from './images/AI-icon.svg';
import icone2 from './images/Archive-icon.svg';
import icone3 from './images/Union.svg';
import refractioLogo from './images/refractio.png';
import validationLogo from './images/Validation.svg';
import emailjs from 'emailjs-com';

function App() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); 

    const templateParams = {
      email: email
    };

    emailjs.send('service_sqjg3td', 'template_05rzmn5', templateParams, 'dUBWs7PTM9pMkDgJ9')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setIsSubmitted(true); 
      }, (err) => {
        console.error('FAILED...', err);
      });
  };


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="Refractio-logo"/>
        <h1>Revolutionizing <span className="highlight">film archives<br /></span> with the power of AI</h1>
      </header>
      <main>
        <img src={mainImage} alt="Main Visual" className="Main-image"/>
        <br></br>
        <div className="feature-section">
          <div className="feature">
            <img src={icone1} alt="Feature 1" className="feature-logo"/>
            <h2>AI-POWERED<br />SEARCH</h2>
            <h3>Leveraging cutting-edge<br />artificial intelligence to<br />revolutionize scene<br />search and analysis</h3>
          </div>
          <div className="feature">
            <img src={icone2} alt="Feature 2" className="feature-logo"/>
            <h2>UNLOCKING THE VALUE<br />OF FILM ARCHIVES</h2>
            <h3>Transforming dormant<br />film collections into<br />accessible resources for<br />the creative industry</h3>
          </div>
          <div className="feature">
            <img src={icone3} alt="Feature 3" className="feature-logo"/>
            <h2>BY FILMMAKERS<br />FOR FILMMAKERS</h2>
            <h3>Developed by a team of<br />passionate cinema<br />professionals who understand<br />the industry's needs</h3>
          </div>
        </div>
        <h1>Unlock the power of cinema's<br /><span className="highlight">past, present, and future</span></h1>
        <form 
          className="email-form"
          onSubmit={handleSubmit}
        >
          <div className="input-group">
            <label>
            <input
            className='email-input'
            placeholder='Enter your email'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
            </label>
            <button type="submit" className="join-button" style={{backgroundColor: isSubmitted ? '#FBC02B' : '', boxShadow: isSubmitted ? '0px 0px 15px 1px #FBC02B' : ''}}>
            {isSubmitted ? <img src={validationLogo} alt="Validated" /> : 'Join Waitlist'}
            </button>
          </div>
        </form>
      </main>
      <br />
      <footer className="App-footer">
        <img src={refractioLogo} alt="Refractio Logo" className="footer-logo"/>
        <div className="footer-content">
          <div className="footer-section">
            <a href="/" className="footer-link"><p>FRANÇAIS</p></a></div>
          <div className="footer-section"><p>©Refractio 2024</p></div>
          <div className="footer-section">
            <a href="mailto:bitran.quentin@refractio.fr" className="footer-link"><p>CONTACT US</p></a>
          </div>
        </div>
        
      </footer>
    </div>
  );
}

export default App;
