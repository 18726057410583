import React, { useState } from 'react';
import './App.css';
import logo from './images/Refractio-logo.svg';
import mainImage from './images/regard-camera.png';
import icone1 from './images/Archive-icon.svg';
import icone2 from './images/AI-icon.svg';    
import icone3 from './images/Union.svg';
import refractioLogo from './images/refractio.png';
import validationLogo from './images/Validation.svg';
import emailjs from 'emailjs-com';

function App() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); 

    const templateParams = {
      email: email
    };

    emailjs.send('service_sqjg3td', 'template_05rzmn5', templateParams, 'dUBWs7PTM9pMkDgJ9')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setIsSubmitted(true); 
      }, (err) => {
        console.error('FAILED...', err);
      });
  };


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="Refractio-logo"/>
        <h1><span className="highlight">Réinventer l'exploitation<br /></span> de l'archive du cinéma</h1>
      </header>
      <main>
        <img src={mainImage} alt="Main Visual" className="Main-image"/>
        <br></br>
        <div className="feature-section">
          <div className="feature">
            <img src={icone1} alt="Feature 1" className="feature-logo"/>
            <h2>VALORISER LES<br />ARCHIVES DE CINÉMA</h2>
            <h3>Améliorer la découvrabilité<br />des collections dormantes<br />pour les industries créatives</h3>
          </div>
          <div className="feature">
            <img src={icone2} alt="Feature 2" className="feature-logo"/>
            <h2>EN RÉINVENTANT<br />L'INDEXATION</h2>
            <h3>Exploiter l'Intelligence Artificielle<br />pour révolutionner la recherche<br />et l'analyse de scènes de films</h3>
          </div>
          <div className="feature">
            <img src={icone3} alt="Feature 3" className="feature-logo"/>
            <h2>PAR DES PASSIONÉS<br />DE CINÉMA</h2>
            <h3>Penser et concevoir une<br />plateforme évolutive au plus<br />près des besoins de l'industrie</h3>
          </div>
        </div>
        <h1>Débloquer le potentiel<br /><span className="highlight">du cinéma d’hier et d’aujourd’hui</span></h1>
        <form 
          className="email-form"
          onSubmit={handleSubmit}
        >
          <div className="input-group">
            <label>
            <input
            className='email-input'
            placeholder='Entrez votre email'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
            </label>
            <button type="submit" className="join-button" style={{backgroundColor: isSubmitted ? '#FBC02B' : '', boxShadow: isSubmitted ? '0px 0px 15px 1px #FBC02B' : ''}}>
            {isSubmitted ? <img src={validationLogo} alt="Validated" /> : 'Rester informé'}
            </button>
          </div>
        </form>
      </main>
      <br />
      <footer className="App-footer">
        <img src={refractioLogo} alt="Refractio Logo" className="footer-logo"/>
        <div className="footer-content">
          <div className="footer-section">
            <a href="/refractio-en" className="footer-link"><p>ENGLISH</p></a></div>
          <div className="footer-section"><p>©Refractio 2024</p></div>
          <div className="footer-section">
            <a href="mailto:bitran.quentin@refractio.fr" className="footer-link"><p>NOUS CONTACTER</p></a>
          </div>
        </div>
        
      </footer>
    </div>
  );
}

export default App;
